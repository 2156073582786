<template>
  <div class="wrapper">
  
      <b-modal title="Info" class="modal-info" v-model="infoModal" @ok="infoModal = false" ok-variant="info" v-bind:ok-only='infoModalCancelDisabled'>
        {{ infoModalContent }}
      </b-modal>
    
	  <div class="animated fadeIn">
		    <div class="row m-y-2">
		        <div class="col-lg-2"></div>
		        <div class="col-lg-8">
		        <form @submit.prevent="userFormSubmit()">
		        	<b-tabs>
		        		<b-tab title="User" :active="!$route.query.action">
		                    <div class="row">
		                        <div class="col-md-6">
																<h5 class="mb-3" v-html="userLabel"></h5>
		                            <template v-if="userFormData.address1||userFormData.zipCode">
			                            <h6>Personal Mailing Address:</h6>
			                            <p>
			                                {{ userFormData.address1 }} {{ userFormData.address2 }} {{ userFormData.city }} {{ userFormData.state }} {{ userFormData.zipCode }}
			                            </p>
		                            </template>
			                            <h6>Personal Contact:</h6>
		                            <p>
		                               {{ userFormData.phone }} <br /> {{ userFormData.email }} 
		                            </p>
		                        </div>
		                    </div>
		        		</b-tab>
		        		<b-tab title="Edit" :active="$route.query.action === 'profile-update'">
		        			<h4 class="m-y-2">Edit Profile</h4>
											<b-alert v-if="userFormData.fullName === userFormData.username" variant="warning" show>
												<big><i class="fa fa-warning"></i></big>
												We notice you haven't updated your name yet. Our old system did not store names, which made it difficult to lookup users or address you properly. 
												Our new system does store names. Please take the time to update your profile with your first and last name.<br />Thank you! :)
											</b-alert>
					            <b-row>
					              <b-col sm="6">
													<b-form-group
														label="First Name"
														label-for="user-firstName"
														:label-cols="3"
														:horizontal="true"
													>
															<b-form-input style="margin-left: 3px;" id="user-firstName" type="text" size="66" placeholder="First Name" v-model="userName.first" :class="{ 'border border-danger': (userName.first <= '') }" required></b-form-input>
													</b-form-group>
					              </b-col>
					              <b-col sm="6">
													<b-form-group
														label="Last Name"
														label-for="user-lastName"
														:label-cols="3"
														:horizontal="true"
													>
															<b-form-input style="margin-left: 3px;" id="user-lastName" type="text" size="66" placeholder="Last Name" v-model="userName.last" :class="{ 'border border-danger': (userName.last <= '') }" required></b-form-input>
													</b-form-group>
												</b-col>
					            </b-row>

					            <b-row>
					              <b-col sm="6">
			                    <b-form-group
							            label="Phone"
							            label-for="user-phone"
							            :label-cols="3"
							            :horizontal="true"
													description="For office use only">
						                <masked-input 
															id="user-phone" 
															class="form-control"
														 :class="{ 'border border-danger': (userFormData.phone <= '') }"
															v-model="userFormData.phone" 
															:mask="['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
															placeholderChar="#"
															placeholder="Personal Phone"
															required
														></masked-input>
						            </b-form-group>
					              </b-col>
                        <b-col sm="6">
                              <b-form-group
                          label="Email"
                          label-for="user-email"
                          :label-cols="3"
                          :horizontal="true"
													description="For office use only">
                            <b-form-input id="user-email" type="text" v-model="userFormData.email"></b-form-input>
                        </b-form-group>
                        </b-col>
					            </b-row>
											<h6>Personal Mailing Address:</h6>
					            <b-row>
					              <b-col sm="8">
			                        <b-form-group
							            label="Street"
							            label-for="user-street1"
							            :label-cols="3"
							            :horizontal="true">
						                <b-form-input id="user-street1" type="text" v-model="userFormData.address1"></b-form-input>
						            </b-form-group>
					              </b-col>
					              <b-col sm="4">
			                        <b-form-group
							            label="Apt"
							            label-for="user-street2"
							            :label-cols="3"
							            :horizontal="true">
						                <b-form-input id="user-street2" type="text" v-model="userFormData.address2"></b-form-input>
						            </b-form-group>
					              </b-col>
					            </b-row>
					            
					            <b-row>
					              <b-col sm="8">
					                <b-form-group
							            label="City"
							            label-for="user-city"
							            :label-cols="3"
							            :horizontal="true">
						                <b-form-input id="user-city" type="text" v-model="userFormData.city"></b-form-input>
						            </b-form-group>
					              </b-col>
					              <b-col sm="4">
					                <b-form-group
							            label="Zip"
							            label-for="user-zip"
							            :label-cols="3"
							            :horizontal="true">
						                <b-form-input id="user-zip" type="text" v-model="userFormData.zipCode"></b-form-input>
						            </b-form-group>
					              </b-col>
					            </b-row>
					            
						        <div
											class="offset-md-2"
										>
               				<b-button type="button" variant="secondary" @click="userFormCancel()" :disabled="!editsPending">Cancel</b-button>
	                    <b-button type="submit" :variant="(editsPending ? 'success' : '')" class="float-right" :disabled="!editsPending">Save Changes</b-button>
						        </div>
					            
		        		</b-tab>
		        		<b-tab title="Credentials">
					            <b-row>
					              <b-col sm="12">
			                        <b-form-group
							            label="Username"
							            label-for="user-username"
							            :label-cols="3"
							            :horizontal="true"
							            description="Your username cannot be changed">
						                <b-form-input id="user-username" type="text" v-model="userFormData.username" disabled></b-form-input>
						            </b-form-group>
						            <b-row>
					              		<b-col></b-col>
					              		<b-col sm="9">
								            <div class="bg-warning px-3">
								              <p class="text-dark">
	                              Only use the fields below to change your current password.
	                            </p>
								            </div>
					                	</b-col>
					            	</b-row>
			                  <b-form-group
							            label="New Password"
							            label-for="user-password-new"
							            :label-cols="3"
							            :horizontal="true">
						                <b-form-input id="user-password-new" type="password" v-model.trim="userFormData.plainPassword" :state="passCheck" aria-describedby="passCheckFeedback"></b-form-input>
                            <b-form-invalid-feedback id="passCheckFeedback">
                              password must be at least 8 characters
                            </b-form-invalid-feedback>
						            </b-form-group>
			                  <b-form-group
							            label="Confirm New Password"
							            label-for="user-password-new2"
							            :label-cols="3"
							            :horizontal="true">
						                <b-form-input 
						                  id="user-password-new2" 
						                  type="password" 
						                  v-model.trim="plainPassword2" 
						                  :state="passMatch"
                              aria-describedby="passMatchFeedback"></b-form-input>
												    <b-form-invalid-feedback id="passMatchFeedback">
												      password fields must match
												    </b-form-invalid-feedback>
						            </b-form-group>
					              </b-col>
					            </b-row>
					            
						        <div class="offset-md-3">
               				<b-button type="button" variant="secondary" @click="userFormCancel()" :disabled="!editsPending">Cancel</b-button>
	                    <b-button class="float-right" type="button" variant="primary" @click="userFormSubmit()" :disabled="!editsPending">Save Changes</b-button>
					          </div>
		        		</b-tab>
                    </b-tabs>
                    </form>
		        </div>
		        <div class="col-lg-2"></div>
		    </div>
		<hr>
	  </div>
  </div>
</template>

<script>
import MaskedInput from 'vue-text-mask'
export default {
  name: 'userProfile',
  data () {
    return {
      infoModalCancelDisabled: true,
			editsPending: false,
			userName: {
				first: null,
				last: null
			},
      userFormData: {},
      plainPassword2: '',
      infoModal: null,
      infoModalContent: null
    }
  },
  computed: {
    userLabel () {
      return this.currentUser.fullName + ' <small>(' + this.currentUserRoleText + ')</small>'
    },
    passMatch () {
      return (this.userFormData.plainPassword === this.plainPassword2) ? true : false
    },
    passCheck () {
      if (! this.userFormData.plainPassword || this.userFormData.plainPassword === '') {
        return true
      }
      return this.userFormData.plainPassword.length > 7 ? true : false
    }
  },
  methods: {
    userFormCancel () {
      this.userFormData = Object.assign({}, this.currentUser)
      this.$nextTick(function () {
        this.editsPending = false
      })
    },
    async userFormSubmit () {
      if (!(this.passMatch && this.passCheck)) {
        alert('please update your password')
        return false
      }
      await this.$recaptchaLoaded()
      // set user data to be updated
      let updatedData = {
        fullName: `${this.userName.first} ${this.userName.last}`,
        email: this.userFormData.email,
        address1: this.userFormData.address1,
        address2: this.userFormData.address2,
        city: this.userFormData.city,
        zip_code: this.userFormData.zipCode,
        country: this.userFormData.country,
        phone: this.userFormData.phone,
        plainPassword: this.userFormData.plainPassword
      }
      updatedData.captcha = await this.$recaptcha('admin_user_form')
      if (updatedData.plainPassword === '') {
        delete updatedData.plainPassword
      }
      let obj = { 
          uid: this.currentUser.id, 
          data: updatedData
      }
      this.$store.dispatch('putUser', obj).then(() => {
				this.showSuccess({ message: 'Your user data has been updated' })
        // this.infoModalContent = 'Your user data has been updated'
        // this.infoModal = true
        this.editsPending = false
        this.$store.dispatch('getCurrentUser', this.currentUser.id)
      }, error => {
        this.formStatus = error
      })
    },
    credsFormSubmit () {
    }
  },
  watch: {
    userFormData: {
      deep: true,
      handler (val) {
        this.editsPending = true
      }
    },
    userName: {
      deep: true,
      handler (val) {
        this.editsPending = true
      }
    }
  },
  mounted () {
			let tmpUserFormData = { ...this.currentUser },
			editsPending = false
			// set user's name
			if (tmpUserFormData.fullName !== tmpUserFormData.username) {
				let userName = tmpUserFormData.fullName.split(' ')
				this.userName.first = userName[0] || null
				this.userName.last = userName[1] || null
			} else editsPending = true
			// set user form data
      this.userFormData = {
        fullName: tmpUserFormData.fullName,
        email: tmpUserFormData.email,
        address1: tmpUserFormData.address1,
        address2: tmpUserFormData.address2,
        city: tmpUserFormData.city,
				zipCode: tmpUserFormData.zipCode,
				phone: tmpUserFormData.phone,
        country: tmpUserFormData.country,
        username: tmpUserFormData.username,
        plainPassword: ''
			}
			if (!editsPending) {
				this.$nextTick(function () {
					this.editsPending = false
				})
			}
  },
  components: { MaskedInput }
}
</script>
